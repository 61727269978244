import React from 'react'
import './Footer.scss'
import Slot from '../widgets/Slot';
import {t} from "../../localizations";

/**
 * This component represents the footer in the store
 */
const Footer = () => {
  return (<div className="footer">
      <div className='footer-column'>
        <img className='footer_logo' src={require('$assets/images/logo.png')}/>
      </div>
      <div className='footer-column'>
        <p className='footer_title'>{t('Footer.Section.Contact')}</p>
        <p className='top_space'>Sägestrasse 21-23<br/>
                                 CH-3550 Langnau
        </p>
        <p><a href="tel:+41344091010" className="href-footer">T. +41 34 409 10 10</a> <br/>
          <a href="mailto:voegeli@voegeli.ch" className="href-footer">voegeli@voegeli.ch</a><br/>
          <a href="https://www.voegeli.ch/de/" className="href-footer" target="_blank">www.voegeli.ch</a>
        </p>
      </div>
    <div className='footer-column'>
      <p className='footer_title'>Kategorien</p>
      <p></p>
    </div>
    <div className='footerPic'>
      <img src={require('$assets/images/pureprint_footer.png')} alt='vögeli green products' className='footer_pic'/>
      </div>
    </div>)
}

export default Footer
